type GetMessageFn<M> = (error: unknown) => M

const defaultGetMessageFn: GetMessageFn<string> = (error: unknown) => error instanceof Error ? error.message : String(error)

export function wrappedError<T extends string, M extends string>(customErrorName: T, getMessage: GetMessageFn<M> = defaultGetMessageFn as GetMessageFn<M>) {
  const klass = {
    [customErrorName]: class extends Error {
      static from(error: unknown) {
        return new this(getMessage(error), error)
      }

      constructor(message?: string, cause?: unknown) {
        super(message)
        this.name = customErrorName
        this.cause = cause
      }
    },
  }

  return klass[customErrorName]
}