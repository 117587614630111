import { wrappedError } from '#/errors'

export const AbortError = wrappedError('AbortError')

export type LoaderOptions<I extends AnyRecord> = {
  body?: I
  headers?: Record<string, string>
  method?: 'get' | 'post'
  query?: I
}

const csrfMethods = new Set([
  'POST',
  'PUT',
  'PATCH',
  'DELETE',
])

class LoadError extends Error {
  response: Response
  body?: unknown

  constructor(response: Response, body?: unknown) {
    super(`${response.status} ${response.statusText}`)
    this.name = 'LoadError'
    this.response = response
    this.body = body
  }
}

export default function loader<I extends AnyRecord>(url: string, options: LoaderOptions<I> & { blob: true }): readonly [load: () => Promise<Blob>, abort: () => void]
export default function loader<I extends AnyRecord>(url: string, options: LoaderOptions<I> & { text: true }): readonly [load: () => Promise<string>, abort: () => void]
export default function loader<I extends AnyRecord, R>(url: string, options: LoaderOptions<I> & { stream: true }): readonly [load: () => Promise<AsyncGenerator<R>>, abort: () => void]
export default function loader<I extends AnyRecord, R>(url: string, options?: LoaderOptions<I> & { stream?: false, blob?: false, text?: false }): readonly [reload: () => Promise<R>, abort: () => void]
export default function loader<I extends AnyRecord, R>(url_: string, options: LoaderOptions<I> & { stream?: boolean, blob?: boolean, text?: boolean } = {}): R {
  const {
    body,
    query,
    blob = false,
    stream = false,
    text = false,
  } = options


  const controller = new AbortController()
  const { signal } = controller

  async function load() {
    const method = options.method?.toUpperCase() ?? 'GET'
    const url = dx.url(url_, query)

    const headers: HeadersInit = {
      'Content-Type': 'application/json',
      ...options.headers,
    }

    if (csrfMethods.has(method)) {
      headers['X-Csrf-Token'] = await (globalThis as any).__csrf__
    }

    const params: RequestInit = {
      method,
      mode: 'cors',
      signal,
      headers,
      credentials: 'include',
    }

    if (body != null) {
      params.body = JSON.stringify(body)
    }

    if (stream) {
      // @ts-expect-error: This isn't currently in the fetch types
      params.duplex = 'half'
    }

    const response = await fetch(url, params)
    
    dx.caching.append({
      url,
      header: response.headers.get('server-timing'),
      initiator: 'client',
    })

    if (signal.aborted) {
      throw signal.reason
    }

    if (!response.ok) {
      try {
        const json = await response.json()
        throw new LoadError(response, json)

      } catch (e) {
        throw new LoadError(response)
      }
    }

    if (stream) {
      const readable = response.body
      __assert(readable, '[loader] Response has no body')
      return iterator<R>(readable, signal)
    }

    if (blob) {
      return response.blob()
    }

    if (text) {
      return response.text()
    }

    const json = await response.json()

    if (signal.aborted) {
      throw signal.reason
    }

    return json
  }

  function abort() {
    return controller.abort(new AbortError())
  }

  return [
    load,
    abort,
  ] as any
}


async function* iterator<T>(stream: ReadableStream, signal: AbortSignal) {
  const reader = stream.getReader()
  const decoder = new TextDecoder()

  try {
    while (true) {
      const { done, value } = await reader.read()

      if (done) {
        return
      }
      
      if (signal.aborted) {
        throw signal.reason
      }

      yield decoder.decode(value) as T
    }

  } finally {
    reader.releaseLock()
  }
}


export function isAbortError(error: unknown): error is typeof AbortError | DOMException {
  if (error instanceof AbortError) {
    return true
  }

  if (error instanceof DOMException && error.message.includes('was aborted')) {
    return true
  }

  return false
}
